import React from "react";
import {
    AboutSection,
    ArticlesSection,
    ContactSection,
    HeroSection,
    InterestsSection,
    ProjectsSection,
} from "gatsby-theme-portfolio-minimal";
// var dic = {
//         "About" : <AboutSection sectionId = "{props.sectionId}" heading = {props.heading}/>,
//         "Articles" : <ArticlesSection sectionId = "{props.sectionId}" heading = "{props.heading}"/>,
//         "Contact" : <ContactSection sectionId = "{props.sectionId}" heading = "{props.heading}"/>,
//         "Hero" : <HeroSection sectionId = "{props.sectionId}" heading = "{props.heading}"/>,
//         "Interests" : <InterestsSection sectionId = "{props.sectionId}" heading = "{props.heading}"/>,
//         "Projects" : <ProjectsSection sectionId = "{props.sectionId}" heading = "{props.heading}"/>
//     }

class Wrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dic: {
                "About": <AboutSection sectionId={props.sectionId} heading={props.heading} />,
                "Articles": <ArticlesSection sectionId={props.sectionId} heading={props.heading} />,
                "Contact": <ContactSection sectionId={props.sectionId} heading={props.heading} />,
                "Hero": <HeroSection sectionId={props.sectionId} heading={props.heading} />,
                "Interests": <InterestsSection sectionId={props.sectionId} heading={props.heading} />,
                "Projects": <ProjectsSection sectionId={props.sectionId} heading={props.heading} />
            }
        }
    }
    render() {

        return (
            <>
                {this.state.dic[this.props.item]}
            </>
        );
    }
}
export default Wrapper